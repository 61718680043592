import axios from "axios";
import { useEffect, useState } from "react";
import { isAdminGrantedSubscription } from "../../../../Utilities/utils";
import * as APIConstants from "../../../Constants/APIConstants";
import PremiumManagementForm from "./PremiumManagementForm";

function PremiumManagementModal(props) {
  const { onClose, user, sessionKey, onSuccess } = props;
  const today = new Date();
  const subscriptionGrantedByAdmin = isAdminGrantedSubscription(
    user.adFreeUserSubscription
  );
  const defaultEndDate = new Date(
    subscriptionGrantedByAdmin &&
    new Date(user.adFreeUserSubscription.endDate) >= today
      ? user.adFreeUserSubscription.endDate
      : today.setDate(today.getDate() + 30)
  );

  const [endDate, setEndDate] = useState(
    defaultEndDate.toISOString().split("T")[0]
  );
  const [error, setError] = useState("");

  function HandleDateChange(e) {
    setError("");
    setEndDate(e.target.value);
  }

  const formProps = {
    onSubmit: Submit,
    onCancel: Revoke,
    onDateChange: HandleDateChange,
    endDate: endDate,
    error: error,
    unchanged: endDate === defaultEndDate.toISOString().split("T")[0],
    hasAdminSubscription: subscriptionGrantedByAdmin,
  };

  async function Submit() {
    if (error) return;

    if (!endDate) {
      setError("End date is required.");
      return;
    }

    if (new Date(endDate) < new Date(today)) {
      setError("End date cannot be before today.");
      return;
    }

    const subscriptionData = {
      id: user.adFreeUserSubscription?.id || null,
      userId: user.id,
      startDate: today.toISOString().split("T")[0],
      endDate: endDate,
      active: true,
    };

    let formData = createFormData(subscriptionData);

    try {
      await PostPremiumSubscription(formData);
      onSuccess();
    } catch (error) {
      console.error("Error making user premium:", error);
      if (error.response && error.response.data) {
        setError(
          "Failed to update subscription: " + error.response.data.errors[0]
        );
        return;
      }
      if (error.response && error.response.status === 401) {
        setError("You are not authorized to perform this action.");
        return;
      }

      setError("An unexpected error occurred. Please try again.");
    }
  }

  async function Revoke() {
    let formData = new FormData();
    formData.append("subscriptionId", user.adFreeUserSubscription.id);
    formData.append("userId", user.id);

    try {
      await axios({
        method: "post",
        url:
          APIConstants.API_URL +
          "UpgradeToPremium/revoke?code=" +
          APIConstants.API_CODE,
        data: formData,
        headers: {
          SessionKey: sessionKey,
        },
      });
      onSuccess();
    } catch (error) {
      console.error("Error revoking subscription:", error);
      if (error.response && error.response.data) {
        setError(`Failed to revoke subscription for ${user.username}`);
        return;
      }
      if (error.response && error.response.status === 401) {
        setError("You are not authorized to perform this action.");
        return;
      }

      setError("An unexpected error occurred. Please try again.");
    }
  }

  function createFormData({ id, userId, startDate, endDate, active }) {
    let formData = new FormData();
    if (id) {
      // If an ID exists, it means we're updating an existing subscription
      formData.append("id", id);
    }
    formData.append("userId", userId);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("active", active.toString());
    return formData;
  }

  async function PostPremiumSubscription(formData) {
    await axios({
      method: "post",
      url:
        APIConstants.API_URL +
        "UpgradeToPremium/admin?code=" +
        APIConstants.API_CODE,
      data: formData,
      headers: {
        SessionKey: sessionKey,
      },
    });
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === "Escape") {
        onClose();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);

  return (
    <div className="modalOverlay" onClick={onClose}>
      <div className="modalContent" onClick={(e) => e.stopPropagation()}>
        <button className="modalCloseButton" onClick={onClose}>
          &times;
        </button>
        <h2 className="modalTitle">{`Manage Premium Access for ${user.username}`}</h2>
        <div className="modalBody moreReadable">
          <PremiumManagementForm {...formProps} />
        </div>
      </div>
    </div>
  );
}

export default PremiumManagementModal;
