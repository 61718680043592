function PremiumManagementForm({
  error,
  endDate,
  onDateChange,
  onSubmit,
  onCancel,
  hasAdminSubscription,
  unchanged,
}) {
  const buttonProps = {
    onSubmit: onSubmit,
    onCancel: onCancel,
    error: error,
    unchanged: unchanged,
  };

  return (
    <>
      <div className="modalInputField">
        <div className="errorText modalItem">{error || "\u00A0"}</div>
        <label htmlFor="endDate">Set end date</label>
        <input
          type="date"
          value={endDate}
          onChange={onDateChange}
          className="modalItem"
          name="endDate"
          id="endDate"
        />
      </div>

      {hasAdminSubscription ? (
        <ManagePremiumButtons {...buttonProps} />
      ) : (
        <GrantPremiumButtons {...buttonProps} />
      )}
    </>
  );
}

export default PremiumManagementForm;

function GrantPremiumButtons(props) {
  const { onSubmit, error } = props;

  return (
    <>
      <div
        className={`funkerButton formButton modalItem ${
          error !== "" ? "disabledButton" : ""
        }`}
        onClick={onSubmit}
      >
        Grant Premium Access
      </div>
    </>
  );
}

function ManagePremiumButtons(props) {
  const { onSubmit, onCancel, error, unchanged } = props;

  return (
    <>
      <div className="modalButtons">
        <div
          className={`funkerButton formButton modalItem ${
            error !== "" || unchanged ? "disabledButton" : ""
          }`}
          onClick={onSubmit}
        >
          Change End Date
        </div>
        <div
          className="funkerButton formButton modalItem alternateButton"
          onClick={onCancel}
        >
          Cancel Premium Subscription
        </div>
      </div>
    </>
  );
}
